import { forwardRef } from "react";
import { Label } from "~/modules/common/ui/label";
import { cn } from "~/modules/common/util/shadcn";

export const MenuItem = forwardRef<
  HTMLLabelElement,
  React.ComponentPropsWithoutRef<typeof Label>
>(({ className, ...props }, ref) => {
  return (
    <Label
      ref={ref}
      {...props}
      className={cn(
        "relative flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm font-semibold outline-none transition-colors focus:bg-slate-100 focus:text-slate-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:focus:bg-slate-800 dark:focus:text-slate-50",
        className
      )}
    />
  );
});
MenuItem.displayName = "MenuItem";
