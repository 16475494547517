import { NavLink } from "@remix-run/react";
import { forwardRef } from "react";
import { MenuItem } from "~/modules/common/component/MenuItem";

export const AccountNavigation = forwardRef<HTMLDivElement>(
  (props, forwardedRef) => {
    return (
      <div {...props} ref={forwardedRef} className="flex-col">
        <NavLink to="/account/profile">
          <MenuItem>Activity</MenuItem>
        </NavLink>
        <NavLink to="/account/billing">
          <MenuItem>Billing</MenuItem>
        </NavLink>
        <NavLink to="/account/settings">
          <MenuItem>Settings</MenuItem>
        </NavLink>
      </div>
    );
  }
);
AccountNavigation.displayName = "AccountNavigation";
